<template>
  <div class="row" style="margin-top: 15%;">
    <div
      class="col-12 col-md-6 mt-2 border-right justify-content-center d-flex align-items-center"
    >
      <h3 class="">
        Mau telekonsul lewat mana?
      </h3>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <div class="card" @click.prevent="openWindow(urlDownload)">
        <div class="card-body">
          <div class="row">
            <div class="col-2 justify-content-center d-flex align-items-center">
              <i class="fa-2x fas fa-cloud-download-alt" style="color: #bad4f4;"></i>
            </div>
            <div class="col-10 text-left">
              <h5 class="card-title">Download Mobile App</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Download Care Dokter Mobile App
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-4" style="border: 1px solid pink;" @click.prevent="openWindow(`${urlOpenApp}?${$route.fullPath.substring(17)}`);">
        <div class="card-body">
          <div class="row">
            <div class="col-2 justify-content-center d-flex align-items-center">
              <img
                src="./../assets/logo_1.png"
                alt=""
                style="width: 40px;height: auto;"
              />
            </div>
            <div class="col-10 text-left">
              <h5 class="card-title">Buka Care Dokter Mobile App</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Sudah punya? klik disini untuk memulai
              </h6>
            </div>
          </div>
        </div>
      </div>
      <h4 class="mt-4 mb-2 text-muted">- atau -</h4>
      <div class="card mt-4" @click.prevent="$router.push(`waitingRoom?${$route.fullPath.substring(17)}`);">
        <div class="card-body">
          <div class="row">
            <div class="col-2 justify-content-center d-flex align-items-center">
              <i class="fa-2x far fa-window-maximize" style="color: #bad4f4;"></i>
            </div>
            <div class="col-10 text-left">
              <h5 class="card-title">Lanjutkan disini</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Lanjutkan tanpa download atau install
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      urlDownload: process.env.VUE_APP_URL_DOWNLOAD,
      urlOpenApp: process.env.VUE_APP_DEEP_LINK
    }
  },
  methods:{
    openWindow(link){
      window.open(link)
    }
  }
};
</script>
<style>
.card{
  border-radius: .6rem;
  box-shadow: 0 0px 1px rgb(0 0 0 / 16%), 0 5px 10px rgb(0 0 0 / 23%);
}
</style>
