<template>
  <div>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm"
      data-toggle="modal"
      :data-target="`#modalShareLink${infoId}`"
      href="#"
    >
      <i class="far fa-share-alt"></i>
      Share Invite Link
    </button>

    <div
      class="modal fade"
      :id="`modalShareLink${infoId}`"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalShareLinkTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Link Telekonsul
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div align="center" class="mt-2" v-if="$store.state.roleDokter">
              <h6>Link Telekonsul Dokter:</h6>
              <div
                class=""
                style="background-color: aliceblue; max-width: 300px; border-radius: 20px;"
              >
                <span
                  class="d-inline-block text-truncate align-middle"
                  style="max-width: 200px;"
                >
                  {{ linkDokter }}
                </span>
                <button
                  type="button"
                  class="btn btn-default btn-copy js-tooltip js-copy"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Copy to clipboard"
                  @click="copyCommand(linkDokter)"
                >
                  <i class="icon fas fa-copy text-dark"></i>
                </button>
              </div>
              <input type="hidden" id="linkUrl" :value="linkDokter" />
            </div>

            <div align="center" class="mt-4">
              <h6>Link Telekonsul Pasien:</h6>
              <div
                class=""
                style="background-color: aliceblue; max-width: 300px; border-radius: 20px;"
              >
                <span
                  class="d-inline-block text-truncate align-middle"
                  style="max-width: 200px;"
                  id="linkPasien"
                >
                  {{ linkPasien }}
                </span>
                <button
                  type="button"
                  class="btn btn-default btn-copy js-tooltip js-copy"
                  data-toggle="tooltip"
                  id="toolPasien"
                  data-placement="bottom"
                  title="Copy to clipboard"
                  @click="copyCommand(linkPasien)"
                >
                  <i class="icon fas fa-copy text-dark"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkPasien: this.$store.state.linkPasien,
      linkDokter: "",
    };
  },
  props: ["infoId"],
  methods: {
    copyCommand(text) {
      // $('#toolPasien').tooltip("show");
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      textField.focus(); //SET FOCUS on the TEXTFIELD
      document.execCommand("copy");
      textField.remove();
      console.log("should have copied " + text);
    },
  },
  mounted() {
    // copy
    function copyToClipboard(text, el) {
      var copyTest = document.queryCommandSupported("copy");
      var elOriginalText = el.attr("data-original-title");

      if (copyTest === true) {
        var copyTextArea = document.createElement("textarea");
        copyTextArea.value = text;
        document.body.appendChild(copyTextArea);
        copyTextArea.select();
        try {
          var successful = document.execCommand("copy");
          var msg = successful ? "Copied!" : "Whoops, not copied!";
          el.attr("data-original-title", msg).tooltip("show");
        } catch (err) {
          console.log("Oops, unable to copy");
        }
        document.body.removeChild(copyTextArea);
        el.attr("data-original-title", elOriginalText);
      } else {
        // Fallback if browser doesn't support .execCommand('copy')
        window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", text);
      }
    }

    $(".js-tooltip").tooltip();

    $(".js-copy").click(function() {
      var text = $(this).attr("data-copy");
      var el = $(this);
      copyToClipboard(text, el);
    });

    this.linkDokter = `${window.location.origin}${localStorage.getItem(
      "linkParams"
    )}`;
  },
};
</script>

<style></style>
