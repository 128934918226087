<template>
  <div class="row" style="margin-top: 15%;">
    <div
      class="col-12 col-md-6 mt-2 border-right justify-content-center d-flex align-items-center"
    >
      <h4 class="">
        Maaf, web browser yang anda gunakan tidak mendukung untuk melakukan
        Telekonsultasi
      </h4>
    </div>
    <div class="col-12 col-md-6 mt-4">
      <div class="card" @click.prevent="openWindow('crdokter.org/mobile')">
        <div class="card-body">
          <div class="row">
            <div class="col-2 justify-content-center d-flex align-items-center">
              <img
                src="./../assets/logo_1.png"
                alt=""
                style="width: 40px;height: auto;"
              />
            </div>
            <div class="col-10 text-left">
              <h5 class="card-title">Care Dokter Mobile App</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Download untuk pengalaman terbaik
              </h6>
            </div>
          </div>
        </div>
      </div>
      <h4 class="mt-4 mb-2 text-muted">- atau -</h4>
      <div class="card mt-4" @click.prevent="openWindow('https://www.google.com/chrome/')">
        <div class="card-body">
          <div class="row">
            <div class="col-2 justify-content-center d-flex align-items-center">
              <img
                src="./../assets/chrome_logo.png"
                alt=""
                style="width: 40px;height: auto;"
              />
            </div>
            <div class="col-10 text-left">
              <h5 class="card-title">Download Google Chrome</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    openWindow(link){
      window.open(link)
    }
  },
};
</script>

<style>
.card{
  border-radius: .6rem;
  box-shadow: 0 0px 1px rgb(0 0 0 / 16%), 0 5px 10px rgb(0 0 0 / 23%);
}
</style>
