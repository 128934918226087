<template>
  <div class="sWaiting">
    <div class="container">
      <div class="" v-if="!browserNotSupported && !permisisonSteps && !option">
        <h1>Working With Your Browser . . .</h1>
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <optionLink v-if="option" />
      <!-- <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div> -->
      <!-- <button @click="pindah">klik</button> -->
      <br />
      <br />

      <div v-if="browserNotSupported">
        <browserNotSupport />
      </div>

      <div v-if="beg">
        <h5>Mohon berikan izin akses kamera dan mikropon</h5>
        <h6>Kami tidak bisa melanjutkan telekonsul jika anda tidak memberi izin :(</h6>
      </div>

      <div v-if="permisisonSteps">
        <h5>Anda memblokir izin akses kamera dan mikropon di browser :(</h5>
        <h6>Berikut adalah langkah-langkah untuk memberikan akses:</h6>
        <div align="left">
          <ol>
            <li>Buka setelan pada browser anda.</li>
            <li>Pada kolom "Privasi dan keamanan", klik Setelan situs.</li>
            <li>
              Pilih izin yang ingin diperbarui. (kamera dan mikropon.)
            </li>
            <li>
              Ubah setelan izin. Perubahan Anda akan disimpan secara otomatis.
            </li>
            <li>
              Untuk mengizinkan situs yang telah Anda blokir: Di bagian
              "Diblokir", pilih nama situs dan ubah kamera atau mikropon izin
              untuk "Izinkan."
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import optionLink from "./../components/optionLink.vue";
import browserNotSupport from "./../components/browserNotSupport.vue";
export default {
  data() {
    return {
      browserNotSupported: false,
      beg: false,
      permisisonSteps: false,
      workingWith: true,
      option: false,
    };
  },
  components: {
    optionLink,
    browserNotSupport,
  },
  methods: {
    pindah() {
      sessionStorage.setItem("permission", "allowed");
      let path = this.$route.fullPath;
      if (
        this.isWebview() ||
        (this.checkBrowser() == "Safari" && this.$route.query.bypass)
      ) {
        this.$router.push(`waitingRoom?${path.substring(17)}`);
      } else if (this.checkMobile() || this.isMobileSafari()) {
        this.option = true;
      } else {
        // kl buka dari web
        this.$router.push(`waitingRoom?${path.substring(17)}`);
      }
    },
    promptToAsk() {
      let vx = this;

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          // alert("got permission", stream);
          // vx.setSession();
          vx.pindah();
          vx.beg = false;
        })
        .catch(function (err) {
          vx.permisisonSteps = true;
          // vx.browserNotSupported = true
          vx.beg = false;
          // alert("failed to ask", err);
        });
    },
    setSession() {
      sessionStorage.setItem("permission", true);
    },
    isMobileSafari() {
      return (
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)
      );
    },
    checkMobile() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    checkBrowser() {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) != -1
      ) {
        return "Opera";
      } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return "Chrome";
      } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return "Safari";
      } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return "Firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") != -1 ||
        !!document.documentMode == true
      ) {
        //IF IE > 10
        return "IE";
      } else {
        return "unknown";
      }
    },
    isWebview() {
      var isWebview =
        (navigator.userAgent.indexOf("Android") !== -1 &&
          navigator.userAgent.indexOf("wv") !== -1) ||
        ((navigator.userAgent.indexOf("iPhone") !== -1 ||
          navigator.userAgent.indexOf("iPad") !== -1) &&
          navigator.userAgent.indexOf("Safari") == -1);
      return isWebview;
    },
  },
  mounted() {
    let vx = this;

    if (this.$route.query.bypass == "mobile" || this.isWebview()) {
      // vx.promptToAsk();
      vx.pindah();

      console.log("bypass mobile");
    } else {
      try {
        navigator.permissions
          .query({ name: "microphone", name: "camera" })
          .then(function (result) {
            if (result.state == "granted") {
              // alert(result.state);
              vx.pindah();
              // vx.setSession();
            } else if (result.state == "prompt") {
              vx.beg = true;
              vx.promptToAsk();
              // alert(result.state);
            } else {
              // alert(result.state);
              vx.promptToAsk();
            }
          });
      } catch (e) {
        if (vx.checkBrowser() == "Safari") {
          this.promptToAsk();
        } else {
          // alert("Browser Not Supported");
          console.log(vx.checkBrowser());
          this.browserNotSupported = true;
        }
        // sarankan dengan browser lain
      }
    }
  },
};
</script>

<style></style>
