<template>
  <div class="container">
    <img src="./../assets/notFound.png" alt="" class="w-50" />
    <br />
    <br />
    <br />

    <h4>Halaman Tidak Ditemukan</h4>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style></style>
